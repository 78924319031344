import {Injectable} from "@angular/core";
import {url_routes} from "../core.routes";
import {HttpClient} from "@angular/common/http";
import {SearchRequest} from "../model/search/SearchRequest";
import {SearchRecord} from "../model/search/SearchRecord";

@Injectable()
export class SearchService {

    searchUrl = url_routes.SEARCH;

    constructor(private http: HttpClient) {

    }

    search(searchText: string): Promise<SearchRecord[]> {
        let searchRequest = new SearchRequest();
        searchRequest.searchText = searchText;
        return this.http.post(this.searchUrl, searchRequest).toPromise().then();
    }
}
