<div class="layout-topbar">
    <p-toast key="topbar" [style]="{marginTop: '70px'}"></p-toast>

    <a routerLink="/" *ngIf="appMain.isHorizontal() && !appMain.isMobile()" class="topbar-logo">
        <img alt="logo" src="assets/layout/images/logo-slim.png" />
        <span class="app-name">AOS DB</span>
    </a>

    <img alt="logo" src="assets/layout/images/logo-slim.png" class="mobile-logo"/>

    <a href="#" class="menu-btn" (click)="appMain.onMenuButtonClick($event)">
        <i class="pi pi-bars"></i>
    </a>

    <a href="#" class="topbar-menu-btn" (click)="appMain.onTopbarMobileMenuButtonClick($event)">
        <i class="pi pi-user"></i>
    </a>

    <div class="layout-topbar-menu-wrapper">
        <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-active': appMain.topbarMenuActive}" (click)="appMain.onTopbarMenuClick($event)">
            <li #profile class="profile-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === profile}" (click)="appMain.onTopbarRootItemClick($event, profile)">
                <a href="#">
                    <span class="profile-image-wrapper">
                        <img [email]="user.email" ngxGravatar/>
                    </span>
                    <span class="topbar-item-name profile-name">{{user.username}}</span>
                </a>
                <ul class="fadeInDown">
                    <li role="menuitem">
                        <a (click)="logout()" href="javascript: void(0);">
                            <i class="pi pi-power-off"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li *ngIf="isSemaforVisible">
                <a href="javascript: void(0);" (click)="refreshLogin()">
                    <span class="topbar-icon material-icons" [style]="{'color': (this.can_login ? 'lightgreen' : 'red'), 'font-size': '24px'}">traffic</span>
                    <span class="topbar-item-name">Semafor</span>
                </a>
                <span class="topbar-item-name profile-name" style="display: block; color: #E8EAF6">{{last_vm}}</span>
            </li>
            <li #settings [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === settings}" (click)="appMain.onTopbarRootItemClick($event, settings)">
                <a href="#">
                    <i class="topbar-icon pi pi-cog"></i>
                    <span class="topbar-item-name">Settings</span>
                </a>
                <ul class="fadeInDown">
                    <li role="menuitem" *ngIf="this.user?.isSuperAdmin()">
                        <a (click)="resetCanLogin()">
                            <span class="material-icons">update</span>
                            <span class="ml-1">Resetnúť semafór</span>
                        </a>
                    </li>
<!--                    <li role="menuitem" *ngIf="user.isSuperAdmin()">-->
<!--                        <a (click)="resetStrategies()">-->
<!--                            <i class="material-icons">refresh</i>-->
<!--                            <span>Resetnúť strategie</span>-->
<!--                        </a>-->
<!--                    </li>-->
                </ul>
            </li>
            <li #searchbox class="search-item" [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === searchbox}" (click)="appMain.onTopbarRootItemClick($event, searchbox)">
                <span class="p-input-icon-right">
                    <input #searchInput type="text" pInputText placeholder="Search" [(ngModel)]="searchText" (keyup)="search()" (keyup.enter)="navigateToFirstSearchRecord()" style="width: 300px"
                           (window:keydown.control.shift.f)="searchInput.focus(); appMain.onTopbarRootItemClick($event, searchbox);"
                    pTooltip="
                    Vyhladava podla market.symbol, market.name, strategy.id, strategy.name, portfolio.id, portfolio.name. Enterom sa presunie na prvy z najdenych vysledkov. Musia byt zadane aspon 3 znaky. Stlacenim CTRL+SHIFT+F focusne vyhladavac.">
                    <i class="topbar-icon pi pi-search"></i>
                </span>
                <ul class="fadeInDown" style="width: 500px">
                    <li role="menuitem" *ngFor="let pair of searchResponse">
                        <a [routerLink]="pair.url">
                            <i class="pi" [ngClass]="{'pi-th-large': pair.type === 'MARKET', 'pi-chart-bar': pair.type === 'PORTFOLIO', 'pi-chart-line': pair.type === 'STRATEGY'}"></i>
                            <span>{{pair.name}}</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
