import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {AuthService} from "../service/auth.service";
import {Observable} from "rxjs";

@Injectable()
export class IndexGuard implements CanActivate {

    constructor(protected authService: AuthService, protected router: Router) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isLoggedIn() && this.authService.getUser() != null) {
            this.router.navigateByUrl('/');
            return false;
        }
        return true;
    }

}
