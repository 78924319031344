import {environment} from "../../environments/environment";

export const url_routes = {
    IMPORT_TRADES: environment.API_ENDPOINT + '/strategy/import',
    REUPLOAD_TRADES: environment.API_ENDPOINT + '/strategy/{strategyId}/reupload',
    WFA_UPLOAD: environment.API_ENDPOINT + '/strategy/wfa',
    MSA_PORTFOLIO_UPLOAD: environment.API_ENDPOINT + '/portfolio/msa',
    PORTFOLIO_SHUFFLE: environment.API_ENDPOINT + '/portfolio/shuffle',
    PORTFOLIO_SHUFFLE_COMBINATIONS: environment.API_ENDPOINT + '/portfolio/shuffle-combination',
    STRATEGY: environment.API_ENDPOINT + '/strategy',
    STRATEGY_LIST: environment.API_ENDPOINT + '/strategy/list',
    MARKET_LIST: environment.API_ENDPOINT + '/market/list',
    MARKET_DATA_LIST: environment.API_ENDPOINT + '/market/data',
    MARKET_TRADE_LIST: environment.API_ENDPOINT + '/market/trades',
    SEARCH_MARKET_TRADES: environment.API_ENDPOINT + '/account/{accountId}/trade/search',
    PORTFOLIO_LIST: environment.API_ENDPOINT + '/portfolio/list',
    MARKET: environment.API_ENDPOINT + '/market',
    ACCOUNT_TRADE: environment.API_ENDPOINT + '/account/{accountId}/trade',
    PORTFOLIO: environment.API_ENDPOINT + '/portfolio',
    PORTFOLIO_UPDATE_PERFORMANCE: environment.API_ENDPOINT + '/portfolio/{portfolioId}/performance',
    MARKET_DETAIL: environment.API_ENDPOINT + '/market/{marketId}',
    PORTFOLIO_DETAIL: environment.API_ENDPOINT + '/portfolio/{portfolioId}',
    COMPARE_PORTFOLIO: environment.API_ENDPOINT + '/strategy/{strategyId}/compare-portfolio',
    MARKET_STRATEGIES: environment.API_ENDPOINT + '/market/strategies',
    MARKET_DATA_TICKERS: environment.API_ENDPOINT + '/market/data/tickers',
    MARKET_DATA_MAX_DD: environment.API_ENDPOINT + '/market/data/max-dd',
    MARKET_DATA_MAX_CORONA_DD: environment.API_ENDPOINT + '/market/data/max-corona-dd',
    STRATEGY_DASHBOARD: environment.API_ENDPOINT + '/strategy/dashboard',
    STRATEGY_STATE: environment.API_ENDPOINT + '/strategy/state',
    STRATEGY_CREATED_AT: environment.API_ENDPOINT + '/strategy/{strategyId}/parent-created',
    CHILD_STRATEGIES: environment.API_ENDPOINT + '/strategy/{strategyId}/child-strategy',
    UPDATE_STRATEGY: environment.API_ENDPOINT + '/strategy/{strategyId}',
    MARK_AS_HISTORY: environment.API_ENDPOINT + '/strategy/{strategyId}/mark-as-history/{periodType}',
    GENERATE_RANDOM_NAME: environment.API_ENDPOINT + '/strategy/{strategyId}/random-name',
    ADD_WFASCREEN: environment.API_ENDPOINT + '/strategy/{strategyId}/wfaScreen',
    PERIOD_PERFORMANCES: environment.API_ENDPOINT + '/strategy/{strategyId}/period-performance?calculateType={calculateType}',
    PERIOD_PERFORMANCES_PORTFOLIO: environment.API_ENDPOINT + '/portfolio/period-performance',
    STATE_STATISTICS: environment.API_ENDPOINT + '/strategy/state-statistic?from={from}&to={to}',
    PERIODICAL_RETURNS: environment.API_ENDPOINT + '/strategy/periodical-returns',
    RESET_STRATEGIES: environment.API_ENDPOINT + '/strategy/reset-strategies',
    ADD_TRADING_PERIOD: environment.API_ENDPOINT + '/strategy/{strategyId}/trading-period',
    ADD_MARKET_VERIFICATION: environment.API_ENDPOINT + '/strategy/{strategyId}/market-verification',
    UPDATE_STRATEGY_STATE: environment.API_ENDPOINT + '/strategy/{strategyId}/state/{state}',
    STRATEGY_TAGS: environment.API_ENDPOINT + '/strategy/tags',
    STRATEGY_FLAGS: environment.API_ENDPOINT + '/strategy/flags',
    STRATEGY_FLAG_DELETE: environment.API_ENDPOINT + '/strategy/{strategyId}/flag/{flagId}',
    STRATEGY_FLAGS_UPDATE: environment.API_ENDPOINT + '/strategy/{strategyId}/flags',
    STRATEGY_TAG: environment.API_ENDPOINT + '/strategy/{strategyId}/tag/{tag}',
    CALCULATE_PERFORMANCE: environment.API_ENDPOINT + '/performance/calculate',
    WFA_IMAGE: environment.API_ENDPOINT + '/file/wfa',
    PORTFOLIO_MSA_IMAGE: environment.API_ENDPOINT + '/file/portfolio',
    READ_DP_REPORT: environment.API_ENDPOINT + '/dp/read',
    MARKET_DATA_UPLOAD: environment.API_ENDPOINT + '/market/data/upload',
    MAGIC_FILTER_DP_REPORT: environment.API_ENDPOINT + '/dp/magic-filter',
    FILE_UPLOAD: environment.API_ENDPOINT + '/strategy/{strategyId}/file',
    FILE_DOWNLOAD: environment.API_ENDPOINT + '/file/{strategyId}/download/',
    STRATEGY_MSA_DOWNLOAD: environment.API_ENDPOINT + '/strategy/{strategyId}/msa',
    PORTFOLIO_MSA_DOWNLOAD: environment.API_ENDPOINT + '/portfolio/{portfolioId}/msa',
    FILE_DELETE: environment.API_ENDPOINT + '/file/{strategyId}/delete/',
    SIMPLIFY: environment.API_ENDPOINT + '/dp/simplify',
    FORMAT: environment.API_ENDPOINT + '/dp/format',
    DP_EXCEL: environment.API_ENDPOINT + '/dp/excel',
    CODE_TYPE: environment.API_ENDPOINT + '/code',
    UPDATE_REOPTIMALIZATION: environment.API_ENDPOINT + '/strategy/{strategyId}/reoptimalization',
    CLONE_STRATEGY: environment.API_ENDPOINT + '/strategy/{strategyId}/clone/{data}',
    UPDATE_DATA: environment.API_ENDPOINT + '/strategy/{strategyId}/data',
    REFRESH_MC_DD: environment.API_ENDPOINT + '/strategy/{strategyId}/refresh-mc-dd',
    WFA_DELETE: environment.API_ENDPOINT + '/strategy/{strategyId}/wfa/',
    ATTRIBUTE_DELETE: environment.API_ENDPOINT + '/strategy/{strategyId}/reoptimalization-attribute/',
    REOPTIMALIZATION_DELETE: environment.API_ENDPOINT + '/strategy/{strategyId}/reoptimalization/',
    TRADING_PERIOD_DELETE: environment.API_ENDPOINT + '/strategy/{strategyId}/trading-period/',
    MARKET_VERIFICATION_DELETE: environment.API_ENDPOINT + '/strategy/{strategyId}/market-verification/',
    DATA_DELETE: environment.API_ENDPOINT + '/strategy/{strategyId}/data/',
    STRATEGY_DELETE: environment.API_ENDPOINT + '/strategy/{strategyId}',
    SEARCH: environment.API_ENDPOINT + '/search',
    HOLIDAY: environment.API_ENDPOINT + '/holiday',
    ACCOUNT: environment.API_ENDPOINT + '/account',
    STATEMENT_ROW_LIST: environment.API_ENDPOINT + '/account/{accountId}/statement-rows',
    CASH_FLOW: environment.API_ENDPOINT + '/account/{accountId}/cash-flow',
    READ_TRADESTATION_REPORT: environment.API_ENDPOINT + '/account/{accountId}/read',
    USER_TICKER: environment.API_ENDPOINT + '/user/ticker',
    STRATEGY_SETTINGS_PERFORMANCE: environment.API_ENDPOINT + '/settings-performance/{strategyId}',
    SETTINGS_PERFORMANCE: environment.API_ENDPOINT + '/settings-performance',
    TRADE: environment.API_ENDPOINT + '/trade/{tradeId}',
};

export const app_routes = {
    STRATEGY_LIST: '/strategy-list',
    MARKET_DETAIL: '/market/{id}'
}
