import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './core/pages/app.notfound.component';
import {AppErrorComponent} from './core/pages/app.error.component';
import {AppAccessdeniedComponent} from './core/pages/app.accessdenied.component';
import {IndexGuard} from "./core/guards";
import {CanActivateAuthGuard} from "./core/guards/can-activate-authguard";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'login',
                loadChildren: () => import('src/app/index/index.module').then(m => m.IndexModule),
                canActivate: [IndexGuard]
            },
            {
                path: '', component: AppMainComponent,
                data: {roles: ['READER'], title: 'Dashboard'},
                canActivate: [CanActivateAuthGuard],
                children: [
                    {path: 'theme', loadChildren: () => import('src/app/theme/theme.module').then(m => m.ThemeModule)},
                    {path: '', loadChildren: () => import('src/app/admin/admin.module').then(m => m.AdminModule)}
                ]
            },
            {path: 'error', component: AppErrorComponent},
            {path: 'accessdenied', component: AppAccessdeniedComponent},
            {path: '404', component: AppNotfoundComponent},
            {path: '**', redirectTo: '/404'},
        ],
        {
            useHash: true,
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            scrollOffset: [0, 108] // [x, y] - adjust scroll offset
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
