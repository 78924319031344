import {Component, OnInit} from '@angular/core';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(public app: AppComponent, public appMain: AppMainComponent) {
    }

    ngOnInit() {
        this.model = [
            {label: 'MENU.DASHBOARD', icon: 'pi pi-home', routerLink: ['/']},
            {label: 'MENU.STRATEGY_LIST', icon: 'pi pi-list', routerLink: ['/strategy-list']},
            {label: 'MENU.ADD_STRATEGY', icon: 'pi pi-plus', routerLink: ['/add-strategy']},
            {label: 'MENU.PORTFOLIO_LIST', icon: 'pi pi-chart-bar', routerLink: ['/portfolio-list']},
            {label: 'MENU.PORTFOLIO', icon: 'pi pi-plus', routerLink: ['/portfolio']},
            {label: 'MENU.PORTFOLIO_SHUFFLE', icon: 'pi pi-sort-amount-down-alt', routerLink: ['/shuffle']},
            {
                label: 'MENU.MARKET', icon: 'pi pi-th-large', items: [
                    {label: 'MENU.MARKET', icon: 'pi pi-th-large', routerLink: ['/market-list']},
                    {label: 'MENU.MARKET_DATA', icon: 'pi pi-th-large', routerLink: ['/market-data']},
                ]
            },
            {
                label: 'MENU.ACCOUNTS', icon: 'pi pi-dollar', items: [
                    {label: 'MENU.TRADESTATION', icon: 'pi pi-user', routerLink: ['/tradestation']},
                    {label: 'MENU.LIVE', icon: 'pi pi-chart-line', routerLink: ['/live']},
                    {label: 'MENU.MARKET_TRADES', icon: 'pi pi-list', routerLink: ['/market-trades']},
                ]
            },
            {
                label: 'Meistertask', icon: 'pi pi-check-square', items: [
                    {label: 'Vseobecne', icon: 'pi pi-check-square', url: ['https://www.meistertask.com/app/project/sIO2I7vQ/vseobecne'], target: '_blank'},
                ]
            },
            {
                label: 'MENU.AUTOMATION', icon: 'pi pi-box', items: [
                    {label: 'MENU.DP_GENERATOR', icon: 'pi pi-file-excel', routerLink: ['/dp-generator']},
                    {label: 'MENU.DP_FILTER', icon: 'pi pi-filter', routerLink: ['/dp-filter']},
                    {label: 'MENU.SIMPLIFIER', icon: 'pi pi-file', routerLink: ['/ocesavac']},
                    {label: 'MENU.PERFORMANCE_SETTINGS', icon: 'pi pi-cog', routerLink: ['/performance-settings']},
                    {label: 'PVE', icon: 'pi pi-server', url: ['https://pve.zitrochu.sk:8006/'], target: '_blank'},
                ]
            },
            {
                label: 'Theme', icon: 'pi pi-prime', items: [
                    {
                        label: 'Favorites', icon: 'pi pi-fw pi-home',
                        items: [
                            {label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/theme/']}
                        ]
                    },
                    {
                        label: 'UI Kit', icon: 'pi pi-fw pi-star', routerLink: ['/theme/uikit'], badge: 6,
                        items: [
                            {label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/theme/uikit/formlayout']},
                            {label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/theme/uikit/floatlabel']},
                            {label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/theme/uikit/invalidstate']},
                            {label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/theme/uikit/input']},
                            {label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/theme/uikit/button'], class: 'rotated-icon'},
                            {label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/theme/uikit/table']},
                            {label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/theme/uikit/list']},
                            {label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/theme/uikit/tree']},
                            {label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/theme/uikit/panel']},
                            {label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/theme/uikit/overlay']},
                            {label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/theme/uikit/media']},
                            {label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/theme/uikit/menu'], preventExact: true},
                            {label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/theme/uikit/message']},
                            {label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/theme/uikit/file']},
                            {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/theme/uikit/charts']},
                            {label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/theme/uikit/misc']}
                        ]
                    },
                    {
                        label: 'Prime Blocks', icon: 'pi pi-fw pi-prime', routerLink: ['/theme/blocks'],
                        items: [
                            {label: 'Free Blocks', icon: 'pi pi-fw pi-eye', routerLink: ['/theme/blocks']},
                            {label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: ['https://www.primefaces.org/primeblocks-ng'], target: '_blank'},
                        ]
                    },
                    {
                        label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['/theme/utilities'],
                        items: [
                            {label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', routerLink: ['/theme/utilities/icons']},
                            {label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: ['https://www.primefaces.org/primeflex/'], target: '_blank'},
                        ]
                    },
                    {
                        label: 'Pages', icon: 'pi pi-fw pi-briefcase', routerLink: ['/theme/pages'], badge: 8, badgeStyleClass: 'teal-badge',
                        items: [
                            {label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/theme/pages/crud']},
                            {label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/theme/pages/calendar']},
                            {label: 'Timeline', icon: 'pi pi-fw pi-calendar', routerLink: ['/theme/pages/timeline']},
                            {label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank'},
                            {label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/theme/login']},
                            {label: 'Invoice', icon: 'pi pi-fw pi-dollar', routerLink: ['/theme/pages/invoice']},
                            {label: 'Help', icon: 'pi pi-fw pi-question-circle', routerLink: ['/theme/pages/help']},
                            {label: 'Error', icon: 'pi pi-fw pi-times-circle', routerLink: ['/theme/error']},
                            {label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/theme/notfound']},
                            {label: 'Access Denied', icon: 'pi pi-fw pi-lock', routerLink: ['/theme/access']},
                            {label: 'Empty', icon: 'pi pi-fw pi-circle-off', routerLink: ['/theme/pages/empty']}
                        ]
                    },
                    {
                        label: 'Hierarchy', icon: 'pi pi-fw pi-align-left',
                        items: [
                            {
                                label: 'Submenu 1', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {
                                        label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
                                        items: [
                                            {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left'},
                                            {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left'},
                                            {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left'},
                                        ]
                                    },
                                    {
                                        label: 'Submenu 1.2', icon: 'pi pi-fw pi-align-left',
                                        items: [
                                            {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left'}
                                        ]
                                    },
                                ]
                            },
                            {
                                label: 'Submenu 2', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {
                                        label: 'Submenu 2.1', icon: 'pi pi-fw pi-align-left',
                                        items: [
                                            {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left'},
                                            {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left'},
                                        ]
                                    },
                                    {
                                        label: 'Submenu 2.2', icon: 'pi pi-fw pi-align-left',
                                        items: [
                                            {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left'},
                                        ]
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        label: 'Start', icon: 'pi pi-fw pi-download',
                        items: [
                            {
                                label: 'Buy Now', icon: 'pi pi-fw pi-shopping-cart', url: ['https://www.primefaces.org/store']
                            },
                            {
                                label: 'Documentation', icon: 'pi pi-fw pi-info-circle', routerLink: ['/theme/documentation']
                            }
                        ]
                    }
                ]
            },
        ];
    }

    onMenuClick() {
        this.appMain.menuClick = true;
    }
}
