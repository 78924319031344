import {Pipe, PipeTransform} from '@angular/core'
import * as moment from 'moment'
import {AppConfig} from "../../app.config";

@Pipe({
    name: 'formatDate'
})
//Usage https://stackoverflow.com/a/39498483/3228330
export class AppDatePipe implements PipeTransform {
    transform(date: any, ...args: any[]) {
        let d = new Date(date)
        return moment(d).format(AppConfig.FRONTED_FORMAT_DATE)
    }

    public static transformFromDateTime(date: string): Date {
        return moment(date, AppConfig.BACKEND_FORMAT_DATETIME).toDate();
    }

    public static transformFromDate(date: string): Date {
        return moment(date, AppConfig.BACKEND_FORMAT_DATE).toDate();
    }

    public static addDays(date: string, days: number): Date {
        return moment(date, AppConfig.BACKEND_FORMAT_DATE).add(days, 'days').toDate();
    }

    public static transformToDate(date: Date): string {
        return moment(date).format(AppConfig.FRONTED_FORMAT_DATE);
    }

    public static transform(date: any, args?: any): any {
        let d = new Date(date)
        return moment(d).format(AppConfig.FRONTED_FORMAT_DATE)
    }

    public static stringToDate(date: string) {
        return AppDatePipe.transform(AppDatePipe.transformFromDateTime(date));
    }

    public static transformDateTime(date: any, args?: any): any {
        let d = new Date(date);
        return moment(d).format(AppConfig.BACKEND_FORMAT_DATETIME);
    }
}
