import {NgModule} from "@angular/core";
import {CanActivateAuthGuard} from "./guards/can-activate-authguard";
import {FileService} from "./service/file.service";
import {IndexGuard} from "./guards";
import {AppDatePipe} from "./util/app.dateFormat";
import {CopyClipboardDirective} from "./util/copy-to-clipboard";
import {KeysPipe} from "./util/keys";
import {FocusOutDirective} from "./util/focus.out";

@NgModule({
    imports: [],
    declarations: [
        AppDatePipe,
        KeysPipe,
        CopyClipboardDirective,
        FocusOutDirective
    ],
    exports: [
        AppDatePipe,
        KeysPipe,
        CopyClipboardDirective,
        FocusOutDirective
    ],
    providers: [FileService, CanActivateAuthGuard, IndexGuard]
})

export class CoreModule {

}
