import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './app.config.component';
import {AppNotfoundComponent} from './core/pages/app.notfound.component';
import {AppErrorComponent} from './core/pages/app.error.component';
import {AppAccessdeniedComponent} from './core/pages/app.accessdenied.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';

import {CountryService} from './theme/service/countryservice';
import {CustomerService} from "./theme/service/customerservice";
import {EventService} from './theme/service/eventservice';
import {IconService} from "./theme/service/iconservice";
import {NodeService} from './theme/service/nodeservice';
import {PhotoService} from "./theme/service/photoservice";
import {ProductService} from "./theme/service/productservice";
import {ConfigService} from "./theme/service/app.config.service";
import {AppBreadcrumbService} from './app.breadcrumb.service';
import {MenuService} from './app.menu.service';

import {IndexGuard} from "./core/guards";
import {AuthService} from "./core/service/auth.service";
import {TokenInterceptor} from "./core/service/token.interceptor";
import {SearchService} from "./core/service/search.service";
import {CoreModule} from "./core/core.module";
import {MarketService} from "./core/service/market.service";

import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {GravatarModule} from 'ngx-gravatar';
import {ButtonModule} from 'primeng/button';
import {InputSwitchModule} from "primeng/inputswitch";
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {RadioButtonModule} from "primeng/radiobutton";
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';
import {MessageService} from "primeng/api";
import {TooltipModule} from "primeng/tooltip";

export function authServiceFactory(authService: AuthService): Function {
    return () => authService.reloadUserFromServer();
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function loadMarkets(marketService: MarketService): Function {
    return () => marketService.markets;
}

@NgModule({
    imports: [
        AppRoutingModule,
        CoreModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        GravatarModule.forRoot({}),
        ButtonModule,
        InputSwitchModule,
        MessagesModule,
        MessageModule,
        RadioButtonModule,
        ScrollPanelModule,
        TabViewModule,
        ToastModule,
        TooltipModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppBreadcrumbComponent,
        AppTopBarComponent,
        AppFooterComponent,

        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService, MenuService, AppBreadcrumbService, ConfigService,
        IndexGuard, AuthService, SearchService, MarketService, MessageService,
        {
            provide: APP_INITIALIZER,
            useFactory: authServiceFactory,
            deps: [AuthService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: loadMarkets,
            deps: [MarketService],
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
