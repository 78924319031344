import {HttpClient} from "@angular/common/http";
import {EventEmitter, Injectable, Output} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {mapTo, tap} from "rxjs/operators";
import {User} from "../model/User";
import {Tokens} from "../model/Tokens";
import {environment} from "../../../environments/environment";

@Injectable()
export class AuthService {
    @Output() userChange: EventEmitter<User> = new EventEmitter();

    private readonly JWT_TOKEN = 'JWT_TOKEN';
    private authUrl = environment.API_ENDPOINT + '/auth/login';
    private refreshUrl = environment.API_ENDPOINT + '/auth/refresh';
    private logoutUrl = environment.API_ENDPOINT + '/auth/logout';
    private userProfileUrl = environment.API_ENDPOINT + '/user/profile';
    private user: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    constructor(private http: HttpClient
                // private crudService: CrudService,
                // private userService: UserService,
                // private config: ConfigService,
                // public jwtHelper: JwtHelperService
    ) {
    }

    public login(user: { username: string, password: string }): Observable<boolean> {
        return this.http.post<Tokens>(this.authUrl, user)
            .pipe(
                tap(tokens => this.doLoginUser(tokens)),
                mapTo(true)
            );
    }

    public logout(): Observable<boolean> {
        console.log('logout');
        return this.http.post<any>(this.logoutUrl, {}).pipe(
            tap(() => this.doLogoutUser()),
            mapTo(true)
        );
    }

    public isLoggedIn() {
        return !!this.getJwtToken();
    }

    public refreshToken() {
        return this.http.post<Tokens>(this.refreshUrl, {}).pipe(tap((tokens: Tokens) => {
            this.storeJwtToken(tokens.token);
        }));
    }

    public getJwtToken() {
        return localStorage.getItem(this.JWT_TOKEN);
    }

    public getUser(): User {
        return this.user.value;
    }

    public getObservableUser(): BehaviorSubject<User> {
        return this.user;
    }

    public reloadUserFromServer(): Promise<any> {
        if (this.isLoggedIn()) {
            return this.http.get<User>(this.userProfileUrl).toPromise()
                .then((user) => {
                    console.log(user);
                    this.setUser(user);
                }).catch(error => {
                    this.logout();
                });
        }
        return null;
    }

    private setUser(user: User) {
        this.user.next(Object.assign(new User(), user));
    }

    public doLogoutUser() {
        this.user = new BehaviorSubject<User>(null);
        this.removeTokens();
    }

    private storeJwtToken(jwt: string) {
        localStorage.setItem(this.JWT_TOKEN, jwt);
    }

    private removeTokens() {
        localStorage.removeItem(this.JWT_TOKEN);
    }

    private doLoginUser(tokens: Tokens) {
        this.setUser(tokens.user);
        localStorage.setItem(this.JWT_TOKEN, tokens.token);
    }

    // is(role: Role): boolean {
    //   return this.isLoggedIn() && this.getUser().is(role);
    // }
    //
    // isAdmin(): boolean {
    //   return this.isLoggedIn() && this.getUser().is(Role.ADMIN);
    // }
    //
    // changePassword(data: {}) {
    //   return this.crudService.postByPath<any>('/auth/changePassword', data);
    // }
    //
    // passwordReset(userId: number, resetToken: string, password): Promise<any> {
    //   return this.crudService.postByPath<any>(this.config.forgotPasswordUrl(userId, resetToken), password);
    // }
    //
    // resetCredentials(value: any): Promise<any> {
    //   return this.crudService.postByPath<any>(this.config.resetCredentialsUrl, value);
    // }

}
