/**
 * Created by Michal Dolnak on 1.9.2017.
 * email: dolnakmichal@gmail.com
 */
import {Injectable} from "@angular/core";
import {AbstractControl} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {HandleError} from "../util/handleError";


@Injectable()
export class FileService {

    constructor(private http: HttpClient) {
    }

    static getFileFormData(event: any, fileParamName: string, customName = null): FormData {
        var file = event.files[0];

        let formData: FormData = new FormData();
        formData.append(fileParamName, file, customName != null ? customName : file.name);
        return formData;
    }

    public postByPath(path, data: any, fileNameField: AbstractControl) {
        return this.http.post(path, data).toPromise()
            .then(
                response => {
                    if (response != null && fileNameField != null) {
                        fileNameField.setValue(JSON.parse(JSON.stringify(response)).name);
                    }
                },
                error => console.log(error)
            ).catch(HandleError);
    }

    public downloadFile(path: string, filename: string): Promise<any> {
        var options = this.getFileOptionsWithHeaders();
        return this.http.get(path, options)
        // .map(
        //     (res) => {
        //         return new Blob([res], {type: 'application/pdf'});
        //     }
        // )
        // .catch(HandleError)
            .toPromise()
            .then(
                (file) => {

                    // use this to open file in new tab

                    // var fileURL = window.URL.createObjectURL(file);
                    // window.open(fileURL);

                    //to download file https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/

                    // IE doesn't allow using a blob object directly as link href
                    // instead it is necessary to use msSaveOrOpenBlob
                    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    //     window.navigator.msSaveOrOpenBlob(file);
                    //     return true;
                    // }

                    // For other browsers:
                    // Create a link pointing to the ObjectURL containing the blob.
                    const data = window.URL.createObjectURL(new Blob([file]));
                    var link = document.createElement('a');
                    link.href = data;
                    link.download = filename;
                    link.click();
                    setTimeout(function () {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        window.URL.revokeObjectURL(data), 100
                    });
                    return true;
                });
    }

    private getFileOptionsWithHeaders(): any {
        const httpOptions = {
            'responseType': 'arraybuffer' as 'json',
            // 'responseType'  : 'blob' as 'json'        //This also worked
        };
        return httpOptions;
    }

}
