<div class="exception-body accessdenied">
    <div class="exception-panel">
        <div class="exception-code">
            <img src="assets//layout/images/exception/401.svg">
        </div>

        <div class="exception-detail">

            <div class="exception-icon">
                <i class="pi pi-lock"></i>
            </div>
            <h1>ACCESS DENIED</h1>
            <p>Please contact the system administrator</p>
            <p-button label="GO TO DASHBOARD" [routerLink]="['/']"></p-button>
        </div>
    </div>
</div>
