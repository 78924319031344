import {Directive, HostListener, Output, EventEmitter} from "@angular/core";

@Directive({
    selector: "[focus-out-directive]"
})
export class FocusOutDirective {
    @Output() onFocusOut: EventEmitter<boolean> = new EventEmitter<false>();

    @HostListener("window:keydown.control.shift.f", ["$event"])
    @HostListener("mouseleave", ["$event"])
    public onListenerTriggered(event: any): void {
        this.onFocusOut.emit(true);
    }

    @HostListener("mouseenter", ["$event"])
    public onListenerTriggeredMouseOver(event: any): void {
        this.onFocusOut.emit(false);
    }
}
