import {HttpErrorResponse} from "@angular/common/http";

export function HandleError(error: HttpErrorResponse | any): Promise<any> {
    let errMsg: string;

    if (error instanceof HttpErrorResponse) {
        let body = error.error || '';
        if (body instanceof ArrayBuffer) {
            body = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(body)));
        }
        errMsg = body.error || JSON.parse(body);
    } else {
        errMsg = error.error ? error.error : error.toString();
    }

    return Promise.reject(errMsg);
}
