<div class="exception-body notfound">
    <div class="exception-panel">
        <div class="exception-code">
            <img src="assets/layout/images/exception/404.svg">
        </div>

        <div class="exception-detail">
            <div class="exception-icon">
                <i class="pi pi-exclamation-circle"></i>
            </div>
            <h1>PAGE NOT FOUND</h1>
            <p>Please contact the system administrator</p>
            <p-button label="GO TO DASHBOARD" [routerLink]="['/']"></p-button>
        </div>
    </div>
</div>
