import {AuthItem} from "./AuthItem";
import {Transaction} from "./Transaction";

export class User {
    id: number;
    email: string;
    username: string;
    authItems: AuthItem[];
    transactions: Transaction[];

    getUserRoles(): string[] {
        return this.authItems.map((value) => {
            return value.name
        });
    }

    is(role: string): boolean {
        return this.getUserRoles().includes(role);
    }

    isSuperAdmin(): boolean {
        return this.is('SUPER_ADMIN');
    }
}
