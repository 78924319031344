import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {PrimeNGConfig} from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    layoutMode = 'horizontal';

    layoutColor = 'light';

    darkMenu = false;

    isRTL = false;

    inputStyle = 'outlined';

    ripple = true;

    constructor(private primengConfig: PrimeNGConfig, translate: TranslateService) {
        translate.setDefaultLang('sk');
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
    }
}
