import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AuthService} from "./core/service/auth.service";
import {Router} from "@angular/router";
import {User} from "./core/model/User";
import {HttpClient} from "@angular/common/http";
import {MessageService} from "primeng/api";
import {interval, Subscription} from "rxjs";
import {url_routes} from "./core/core.routes";
import {environment} from "../environments/environment";
import {SearchService} from "./core/service/search.service";
import {SearchRecord} from "./core/model/search/SearchRecord";

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

    user: User;
    can_login = 0;
    last_vm = '';
    subscription: Subscription;

    searchText: string;
    searchResponse: SearchRecord[];

    constructor(public appMain: AppMainComponent, private authService: AuthService, private router: Router, private http: HttpClient,
                private service: MessageService, private searchService: SearchService) {
        this.authService.getObservableUser().subscribe(u => this.user = u);
    }

    logout() {
        this.authService.logout().subscribe(result => {
            this.router.navigateByUrl('/login')
        });
    }

    ngOnInit(): void {
        if (this.isSemaforVisible()) {
            const source = interval(10000);
            this.refreshLogin(false);
            this.subscription = source.subscribe(val => this.refreshLogin(false));
        }
    }

    isSemaforVisible() {
        return environment.production && location.hostname !== 'aos.zitrochu.sk';
    }

    refreshLogin(withToast = true): void {
        this.http.get('https://www.zitrochu.sk/automation/get_last.php').toPromise().then(r => {
            this.last_vm = String(r);
        });
        this.http.get('https://www.zitrochu.sk/automation/can_login.php').toPromise().then(r => {
            this.can_login = Number(r);
            if (withToast) {
                this.service.add({
                    key: 'topbar',
                    severity: 'success',
                    summary: 'Request o update bol úspešný',
                    detail: 'Podľa farby semafóru sa možeš lognuť'
                });
            }
        }).catch(e => {
            if (withToast) {
                this.service.add({
                    key: 'topbar',
                    severity: 'error',
                    summary: 'Request o stave semafóru nebol úspešný',
                    detail: e
                });
            }
        })
    }

    resetCanLogin(): void {
        this.http.get('https://www.zitrochu.sk/automation/update_login.php?can_login=1').toPromise().then(r => {
            this.can_login = 1;
            this.service.add({
                key: 'topbar',
                severity: 'success',
                summary: 'Request o reset bol úspešný',
                detail: 'Semafór bol resetnutý'
            });
        }).catch(e => {
            this.service.add({
                key: 'topbar',
                severity: 'error',
                summary: 'Request o reset semafóru nebol úspešný',
                detail: e
            });
        })

    }

    resetStrategies() {
        this.http.get(url_routes.RESET_STRATEGIES).toPromise().then(r => {
            console.log(r);
        });
    }

    search() {
        if (this.searchText && this.searchText.trim().length >= 3) {
            this.searchService.search(this.searchText.trim()).then(r => this.searchResponse = r);
        }
    }

    navigateToFirstSearchRecord() {
        if (this.searchResponse != null && this.searchResponse.length > 0) {
            this.router.navigateByUrl(this.searchResponse[0].url);
        }
    }
}
