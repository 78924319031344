import {Injectable} from "@angular/core";
import {Market} from "../model/Market";
import {HttpClient} from "@angular/common/http";
import {Paginator} from "../../shared/table/service/Paginator";
import {url_routes} from "../core.routes";
import {MarketStrategy} from "../model/dto/MarketStrategy";
import {MarketData} from "../model/MarketData";
import {MarketTrade} from "../model/MarketTrade";

@Injectable()
export class MarketService extends Paginator {

    private _markets: Market[];

    constructor(private http: HttpClient) {
        super(http);
        this._page = url_routes.MARKET_LIST;
    }

    get markets(): Promise<Market[]> {
        if (this._markets == null) {
            this._markets = null;
            return this.http.get(url_routes.MARKET)
                .toPromise()
                .then((response: any) => {
                    this._markets = response;
                    return this._markets;
                });
        } else {
            return Promise.resolve(this._markets);
        }
    }

    getMarketById(id: string): Market {
        for (let m of this._markets) {
            if (id === m.symbol) return m;
        }
        return null;
    }

    getMarket(marketId: string, force: boolean): Promise<Market> {
        if (this._markets == null || force) {
            return this.http.get<Market>(url_routes.MARKET_DETAIL.replace('{marketId}', marketId)).toPromise().then(r => {
                return r;
            });
        } else {
            for (let market of this._markets) {
                if (market.symbol == marketId) {
                    return Promise.resolve(market);
                }
            }
            return null;
        }
    }

    getMarketStrategies(): Promise<MarketStrategy[]> {
        return this.http.get<MarketStrategy[]>(url_routes.MARKET_STRATEGIES).toPromise().then(r => {
            return r;
        });
    }

    createMarket(value: any): Promise<Market> {
        return this.http.post<Market>(url_routes.MARKET, value).toPromise().then(r => {
            return r;
        });
    }

    deleteMarket(marketId: string) {
        return this.http.delete<Market>(url_routes.MARKET + '/' + marketId).toPromise().then(r => {
            return r;
        });
    }

    getAvailableDataTickers(): Promise<string[]> {
        return this.http.get<string[]>(url_routes.MARKET_DATA_TICKERS).toPromise().then(r => {
            return r;
        });
    }

    getMaxData(limit: Number): Promise<MarketData[]> {
        return this.http.get<MarketData[]>(url_routes.MARKET_DATA_MAX_DD + '?limit=' + limit).toPromise().then(r => {
            return r;
        });
    }

    getMaxCoronaData() {
        return this.http.get<MarketData[]>(url_routes.MARKET_DATA_MAX_CORONA_DD).toPromise().then(r => {
            return r;
        });
    }

    addMarketTrades(accountId: number, marketTrades: MarketTrade[]): Promise<MarketTrade[]> {
        return this.http.post<MarketTrade[]>(url_routes.ACCOUNT_TRADE.replace('{accountId}', String(accountId)), marketTrades).toPromise().then(r => {
            return r;
        });
    }
}
