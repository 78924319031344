import {HttpClient, HttpParams} from "@angular/common/http";
import {Pageable} from "../model/Pageable";

export abstract class Paginator {

    protected _page: string;

    protected constructor(protected httpClient: HttpClient) {
    }

    setPage(value: string) {
        this._page = value;
    }

    getPageablePath(): string {
        return this._page;
    };

    getData<T>(pageable: Pageable): Promise<T> {
        let params = new HttpParams()
            .set("size", String(pageable.size))
            .set("page", String(pageable.page))
            .set("filters", JSON.stringify(pageable.filters));
        if (pageable.sort != null) {
            params = params.set("sort", String(pageable.sort) + ',' + (pageable.direction == 1 ? 'asc' : 'desc'));
        }

        return this.httpClient.get<T>(this.getPageablePath(), {params: params}).toPromise();
    };
}
