<div class="layout-wrapper" (click)="onWrapperClick()"
     [ngClass]="{'layout-wrapper-static': app.layoutMode === 'static',
                'layout-wrapper-active': mobileMenuActive,
                'layout-menu-horizontal': app.layoutMode === 'horizontal',
                'layout-rtl': app.isRTL, 'p-ripple-disabled': !app.ripple, 'p-input-filled': app.inputStyle === 'filled'}">

    <app-menu></app-menu>

    <div class="layout-main">
        <app-topbar></app-topbar>

        <app-breadcrumb></app-breadcrumb>

        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <app-config></app-config>
        <app-footer></app-footer>
        <div class="layout-main-mask" *ngIf="mobileMenuActive"></div>
    </div>

</div>
